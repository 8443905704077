import { Grid } from "@mui/material";
import Logo from "../Assets/IMG/Logo.svg";
import Facebook from "../Assets/IMG/Facebook.svg";
import Twitter from "../Assets/IMG/Twitter.svg";
import Linkedin from "../Assets/IMG/Linkedin.svg";
import Instagram from "../Assets/IMG/Instagram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const getCurrentYear = () => {
    const d = new Date(Date.now());
    return d.getFullYear();
  };
  const extraSmallFooterGrid = 12;
  const smallFooterGrid = 6;
  const mediumFooterGrid = 4;
  const largeFooterGrid = 4;
  const extraLargeFooterGrid = 3;
  return (
    <div className="footer-container flex-column">
      <center>
        <div className="footer-top flex-row">
          <Grid
            container
            spacing={5}
            alignItems="flex-start"
            justifyContent={"space-between"}
          >
            <Grid
              xs={extraSmallFooterGrid}
              sm={smallFooterGrid}
              md={mediumFooterGrid}
              lg={largeFooterGrid}
              xl={extraLargeFooterGrid}
            >
              <div className="footer-item flex-column">
                <img src={Logo} alt="" />
                <br />
                <div className="footer-social-row flex-row">
                  <img src={Facebook} alt="" />
                  <img src={Twitter} alt="" />
                  <img src={Instagram} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </Grid>
            <Grid
              xs={extraSmallFooterGrid}
              sm={smallFooterGrid}
              md={mediumFooterGrid}
              lg={largeFooterGrid}
              xl={extraLargeFooterGrid}
            >
              <div className="footer-item flex-column">
                <span className="weight-500 px-19 text-gray-tertiary footer-head">
                  Air Sierra Leone
                </span>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Destinations
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  About Us
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Contact Us
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  FAQ
                </Link>
              </div>
            </Grid>
            <Grid
              xs={extraSmallFooterGrid}
              sm={smallFooterGrid}
              md={mediumFooterGrid}
              lg={largeFooterGrid}
              xl={extraLargeFooterGrid}
            >
              <div className="footer-item flex-column">
                <span className="weight-500 px-19 text-gray-tertiary footer-head">
                  Services
                </span>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Domestic
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Charter
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Corporate Travel
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Group Travel
                </Link>
              </div>
            </Grid>
            <Grid
              xs={extraSmallFooterGrid}
              sm={smallFooterGrid}
              md={mediumFooterGrid}
              lg={largeFooterGrid}
              xl={extraLargeFooterGrid}
            >
              <div className="footer-item flex-column">
                <span className="weight-500 px-19 text-gray-tertiary footer-head">
                  Legal
                </span>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Terms & Conditions
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Refund Policy
                </Link>
                <Link
                  to="/"
                  className="px-15 text-gray-tertiary weight-400 footer-link"
                >
                  Travel Application Agency
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </center>
      <div className="footer-bottom flex-row text-white px-14">
        Air Seirra Leone Copyright &copy; {getCurrentYear()}
      </div>
    </div>
  );
};

export default Footer;
