import ArrowForwardBlack from "../Assets/IMG/ArrowForwardBlack.svg";
import { Link } from "react-router-dom";

const OurDestinationsHome = () => {
  return (
    <div className="our-destinations flex-column">
      <span className="px-28 weight-600 text-black-secondary">
        Our Destinations
      </span>
      <br />
      <span className="px-14 text-gray-secondary text-center">
        Air Sierran Leone offer’s safe, convenient, and affordable flights to
        more than ten different domestic destinations within Nigeria.
      </span>
      <div className="our-destinations-row flex-row">
        <div className="our-destination-item our-destination-large-item flex-column">
          <div className="our-destination-top our-destination-top-large our-destination-lagos flex-column">
            <div className="px-23 text-white weight-600">Lagos</div>
            <div className="px-13 text-white">
              Lagos has a relatively low cost of living compared to other major
              cities in Africa, making it an attractive place to live and work.
            </div>
          </div>
          <div className="our-destination-bottom our-destination-large-bottom flex-row">
            <Link className="px-13 text-blue-default">
              Book Now &nbsp; &nbsp;{" "}
              <img
                className="our-destination-item-icon"
                src={ArrowForwardBlack}
                alt=""
              />
            </Link>
          </div>
        </div>

        <div className="our-destinations-col flex-column">
          <div className="our-destination-grid flex-row">
            <div className="our-destination-item our-destination-small-item flex-column">
              <div className="our-destination-top our-destination-top-small our-destination-abuja flex-column">
                <div className="px-23 text-white weight-600">Abuja</div>
                <div className="px-13 text-white">
                  Abuja is known for its beautiful landscape, with a mix of
                  rolling hills, natural reserves, and man-made lakes and
                  gardens.
                </div>
              </div>
              <div className="our-destination-bottom our-destination-small-bottom flex-row">
                <Link className="px-13 text-blue-default">
                  Book Now &nbsp; &nbsp;{" "}
                  <img
                    className="our-destination-item-icon"
                    src={ArrowForwardBlack}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="our-destination-item our-destination-small-item flex-column">
              <div className="our-destination-top our-destination-top-small our-destination-yaounde flex-column">
                <div className="px-23 text-white weight-600">Yaounde</div>
                <div className="px-13 text-white">
                  Yaounde is home to many cultural and historical attractions,
                  including museums, galleries, and landmarks.
                </div>
              </div>
              <div className="our-destination-bottom our-destination-small-bottom flex-row">
                <Link className="px-13 text-blue-default">
                  Book Now &nbsp; &nbsp;{" "}
                  <img
                    className="our-destination-item-icon"
                    src={ArrowForwardBlack}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="our-destination-grid flex-row">
            <div className="our-destination-item our-destination-small-item flex-column">
              <div className="our-destination-top our-destination-top-small our-destination-accra flex-column">
                <div className="px-23 text-white weight-600">Accra</div>
                <div className="px-13 text-white">
                  Accra is a major cultural and economic hub in West Africa,
                  with a rich history and a diverse population.
                </div>
              </div>
              <div className="our-destination-bottom our-destination-small-bottom flex-row">
                <Link className="px-13 text-blue-default">
                  Book Now &nbsp; &nbsp;{" "}
                  <img
                    className="our-destination-item-icon"
                    src={ArrowForwardBlack}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="our-destination-item our-destination-small-item flex-column">
              <div className="our-destination-top our-destination-top-small our-destination-dakar flex-column">
                <div className="px-23 text-white weight-600">Dakar</div>
                <div className="px-13 text-white">
                  Dakar is known for its beautiful beaches and coastal setting,
                  with many opportunities for water sports and other outdoor
                  activities.
                </div>
              </div>
              <div className="our-destination-bottom our-destination-small-bottom flex-row">
                <Link className="px-13 text-blue-default">
                  Book Now &nbsp; &nbsp;{" "}
                  <img
                    className="our-destination-item-icon"
                    src={ArrowForwardBlack}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="load-more-btn px-14 text-gray-secondary flex-row bg-white">
        Load More Offers
      </button>
    </div>
  );
};

export default OurDestinationsHome;
