import { useState, useRef } from "react";

import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { message } from "antd";

import { validateEmail } from "..";
import JobData from "../Assets/JSON/JobDetails.json";

const JobDetails = () => {
  const Job = JobData.Job;

  const [ApplicationFormDetails, setApplicationFormDetails] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    CoverLetter: "",
    CV: "",
  });

  const CoverLetterUploadRef = useRef();
  const CvUploadRef = useRef();

  const UploadCV = () => {
    CvUploadRef.current.click();
  };
  const UploadCoverLetter = () => {
    CoverLetterUploadRef.current.click();
  };

  const extraSmallFooterGrid = 12;
  const smallFooterGrid = 6;
  const mediumFooterGrid = 4;
  const largeFooterGrid = 4;
  const extraLargeFooterGrid = 4;

  const [isFirstNameError, setFirstNameError] = useState(false);
  const [isLastNameError, setLastNameError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [isPhoneError, setPhoneError] = useState(false);

  const [isApplicationProceeding, setApplicationProceeding] = useState(false);
  const applyNow = () => {
    const { FirstName, LastName, Email, Phone } = ApplicationFormDetails;
    const isEmailValid = validateEmail(Email);
    if (FirstName.length === 0) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (LastName.length === 0) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (Phone.length === 0) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    if (isEmailValid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    if (
      FirstName.length > 0 &&
      LastName.length > 0 &&
      Phone.length > 0 &&
      isEmailValid
    ) {
      setApplicationProceeding(true);
      setTimeout(() => {
        setApplicationProceeding(false);
        message.success("Your application has been submitted!");
      }, 1500);
    } else {
      message.error("Please fill out all fields!");
    }
  };
  return (
    <div className="job-details-container">
      <div className="bg-green-default job-details-banner px-14 weight-500 flex-row">
        <Container maxWidth="xl">Job Details</Container>
      </div>
      <Container maxWidth="xl">
        <div className="job-details-box bg-white">
          <div className="px-17 text-gray-tertiary weight-500 job-details-title">
            {Job.title}
          </div>
          <div className="job-grid">
            <center>
              <Grid
                container
                alignItems="flex-start"
                justifyContent={"flex-start"}
              >
                {Job.details.map((jobDetail, index) => {
                  return (
                    <Grid
                      xs={extraSmallFooterGrid}
                      sm={smallFooterGrid}
                      md={mediumFooterGrid}
                      lg={largeFooterGrid}
                      xl={extraLargeFooterGrid}
                      key={index}
                    >
                      <span className="job-grid-item flex-column px-17 text-gray-tertiary">
                        <span className="weight-500">
                          <i className="fas fa-circle job-grid-item-dot"></i>{" "}
                          {jobDetail.title}
                        </span>
                        <span className="weight-400">{jobDetail.value}</span>
                      </span>
                    </Grid>
                  );
                })}
              </Grid>
            </center>
          </div>
          <div className="job-skills-grid flex-column">
            <span className="text-gray-tertiary px-19 weight-500">
              Must Have Skills
            </span>
            <center className="job-skills-center">
              <Grid
                container
                alignItems="flex-start"
                justifyContent={"flex-start"}
              >
                {Job.skills.map((skill, index) => {
                  return (
                    <Grid
                      xs={extraSmallFooterGrid}
                      sm={smallFooterGrid}
                      md={mediumFooterGrid}
                      lg={4}
                      xl={3}
                      justifyContent="center"
                      alignItems="center"
                      key={index}
                    >
                      <span className="job-skill flex-row px-15 bg-white-darker">
                        {skill}
                      </span>
                    </Grid>
                  );
                })}
              </Grid>
            </center>
          </div>
          <div className="job-bogus-row flex-row">
            <div className="flex-column">
              <span className="text-gray-tertiary px-20 weight-600">
                Qualifications
              </span>
              <br />
              {Job.Qualifications.map((qualification, index) => {
                return (
                  <span
                    className="px-16 text-gray-tertiary job-qualification flex-row weight-400"
                    key={index}
                  >
                    <span className="job-qualification-dot">
                      <i className="fas fa-circle"></i>
                    </span>
                    {qualification}
                  </span>
                );
              })}
            </div>
            <div className="job-bogus-row-rule"></div>
            <div className="flex-column">
              <span className="text-gray-tertiary px-20 weight-600">
                Commitment to Excellence
              </span>
              <br />
              <span className="px-16 text-gray-tertiary job-commitment flex-row weight-400">
                Our commitment to building a world-class airline with
                exceptional customer service and superior safety standards is a
                social responsibility that every new gTalent joining Green
                Africa must uphold when it comes to our Safety, Security,
                Culture, and 5- Point Promise to Customers.
              </span>
            </div>
          </div>

          <div className="job-role flex-column">
            <span className="text-gray-tertiary px-20 weight-600">
              Job Role
            </span>
            <br />
            <span className="px-16 text-gray-tertiary job-commitment flex-row weight-400">
              {Job.RoleHeading}
            </span>
            <br />
            {Job.Roles.map((role, index) => {
              return (
                <span
                  className="px-16 text-gray-tertiary job-qualification flex-row weight-400"
                  key={index}
                >
                  <span className="job-qualification-dot">
                    <i className="fas fa-circle"></i>
                  </span>
                  {role}
                </span>
              );
            })}
          </div>
        </div>

        <div className="job-details-box bg-white">
          <div className="px-17 text-gray-tertiary weight-500 job-details-title">
            Apply
          </div>
          <div className="job-form flex-column">
            <div className="job-form-row flex-row">
              <div className="job-form-input-container flex-column">
                <span className="px-14 text-gray-tertiary">First Name</span>
                <input
                  type="text"
                  spellCheck={false}
                  placeholder="Enter First Name"
                  className={`job-form-input ${
                    isFirstNameError ? "default-input-error" : ""
                  }`}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setFirstNameError(false);
                    }
                    setApplicationFormDetails({
                      ...ApplicationFormDetails,
                      FirstName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="job-form-input-container flex-column">
                <span className="px-14 text-gray-tertiary">Last Name</span>
                <input
                  type="text"
                  spellCheck={false}
                  placeholder="Enter Last Name"
                  className={`job-form-input ${
                    isLastNameError ? "default-input-error" : ""
                  }`}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setLastNameError(false);
                    }
                    setApplicationFormDetails({
                      ...ApplicationFormDetails,
                      LastName: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="job-form-row flex-row">
              <div className="job-form-input-container flex-column">
                <span className="px-14 text-gray-tertiary">Phone Number</span>
                <input
                  type="text"
                  spellCheck={false}
                  placeholder="Enter Phone Number"
                  className={`job-form-input ${
                    isPhoneError ? "default-input-error" : ""
                  }`}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setPhoneError(false);
                    }
                    setApplicationFormDetails({
                      ...ApplicationFormDetails,
                      Phone: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="job-form-input-container flex-column">
                <span className="px-14 text-gray-tertiary">Email Address</span>
                <input
                  type="text"
                  placeholder="Enter Email Address"
                  className={`job-form-input ${
                    isEmailError ? "default-input-error" : ""
                  }`}
                  spellCheck={false}
                  onChange={(e) => {
                    if (validateEmail(e.target.value)) {
                      setEmailError(false);
                    }
                    setApplicationFormDetails({
                      ...ApplicationFormDetails,
                      Email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <input
              type="file"
              className="none-display"
              ref={CvUploadRef}
              onChange={(e) => {
                const { files } = e.target;
                setApplicationFormDetails({
                  ...ApplicationFormDetails,
                  CV: files[0].name,
                });
              }}
            />
            <input
              type="file"
              className="none-display"
              ref={CoverLetterUploadRef}
              onChange={(e) => {
                const { files } = e.target;
                setApplicationFormDetails({
                  ...ApplicationFormDetails,
                  CoverLetter: files[0].name,
                });
              }}
            />
            <div className="job-form-upload flex-column">
              <span className="px-14 text-gray-tertiary">Cover Letter</span>
              <div className="job-form-upload-row flex-row">
                <span className="px-14 text-gray-tertiary">Upload</span>
                <span
                  className="job-form-upload-btn flex-row"
                  onClick={UploadCoverLetter}
                >
                  Choose File...
                </span>
                {ApplicationFormDetails.CoverLetter.length > 0 && (
                  <span className="px-14 text-gray-tertiary">
                    &nbsp; &nbsp;
                    {ApplicationFormDetails.CoverLetter}
                  </span>
                )}
              </div>
            </div>

            <div className="job-form-upload flex-column">
              <span className="px-14 text-gray-tertiary">CV</span>
              <div className="job-form-upload-row flex-row">
                <span className="px-14 text-gray-tertiary">Upload</span>
                <span
                  className="job-form-upload-btn flex-row"
                  onClick={UploadCV}
                >
                  Choose File...
                </span>
                {ApplicationFormDetails.CV.length > 0 && (
                  <span className="px-14 text-gray-tertiary">
                    &nbsp; &nbsp;
                    {ApplicationFormDetails.CV}
                  </span>
                )}
              </div>
            </div>
            <br />
            <button
              className="btn-default px-16 text-white bg-green-tertiary contact-us-btn"
              onClick={applyNow}
              disabled={isApplicationProceeding}
            >
              {isApplicationProceeding ? (
                <>
                  Sending&nbsp; <i className="fal fa-spinner fa-spin"></i>
                </>
              ) : (
                <>Apply Now</>
              )}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default JobDetails;
