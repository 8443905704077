import { useState } from "react";

import { Container } from "@mui/material";
import { message } from "antd";

import MegaSearchMenu from "./MegaSearchMenu";
import OurDestinationsHome from "./OurDestinationsHome";
import YourGreatDestination from "./YourGreatDestination";

import { validateEmail } from "..";

import EmailIcon from "../Assets/IMG/EmailIcon.svg";

export default function Home() {
  const [emailAddress, setEmailAddress] = useState("");

  const subscribeToNewsletter = () => {
    const isEmailValid = validateEmail(emailAddress);
    if (isEmailValid) {
      //User Email is valid
      message.success("You have successfully subscribed!");
    } else {
      message.error("Please enter a valid email address!");
    }
  };
  return (
    <>
      <div className="home-container">
        <div className="home-jumbotron">
          <Container maxWidth="xl">
            <div className="home-jumbotron-content flex-column">
              <span className="jumbo-header text-white">
                Exclusive Limited Time Offer
              </span>
              <br />
              <br />
              <span className="text-white px-15">
                Get rewarded for your travels. Fly to Lagos and Abuja from Kano
              </span>
              <br />
              <br />
              <div className="jumbo-dots flex-row">
                <span className="jumbo-dot flex-row"></span>
                <span className="jumbo-dot jumbo-dot-filled flex-row"></span>
                <span className="jumbo-dot jumbo-dot-filled flex-row"></span>
              </div>
            </div>
            <MegaSearchMenu />
          </Container>
        </div>
        <br />
        <br />
        <OurDestinationsHome />
        <YourGreatDestination />

        <div className="offers-home-container flex-row">
          <div className="offers-home flex-column">
            <span className="px-28 weight-600 text-black-secondary">
              Never miss our offers
            </span>
            <span className="px-14 text-gray-secondary text-center">
              Subscribe and be the first to receive our exclusive offers
            </span>
            <div className="offers-email-input-container flex-row">
              <img src={EmailIcon} alt="" />
              <input
                type="text"
                placeholder="Email Address"
                className="offers-email-input px-17 weight-400"
                spellCheck={false}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                }}
              />
            </div>
            <div className="offers-terms-row flex-row">
              <input type="checkbox" name="" id="" /> &nbsp;&nbsp;
              <span className="px-13 text-gray-secondary">
                I would like to get offers from Air Sierra Leone . I have read
                and understand the{" "}
                <a href="#none" className="underline text-green-secondary">
                  privacy policy
                </a>{" "}
              </span>
            </div>
            <span
              className="offers-subscribe btn-default bg-blue-default btn-blue-default px-15 text-white text-center flex-row"
              onClick={subscribeToNewsletter}
            >
              Subscribe
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
