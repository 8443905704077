import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "antd/dist/reset.css";

import Home from "./Components/Home";
import "./Assets/CSS/All.css";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Recruitment from "./Components/Recruitment";
import JobDetails from "./Components/JobDetails";
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
const getFullDate = (date) => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const d = new Date(date);
  const month = MONTHS[d.getMonth()];
  const dateNum = d.getDate();
  const year = d.getFullYear();
  const fullDateString = `${dateNum} ${month}, ${year}`;
  return fullDateString;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/job/:jobID" element={<JobDetails />} />
      </Routes>
      <Footer />
    </Router>
  </>
);
export { validateEmail, getFullDate };
