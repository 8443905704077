const defaultAllValue = [
  {
    name: "All",
    value: "all",
  },
];

const JobFunctions = [
  ...defaultAllValue,
  {
    name: "Finance",
    value: "finance",
  },
  {
    name: "Tech",
    value: "tech",
  },
  {
    name: "I.T Operation",
    value: "it-operation",
  },
  {
    name: "Aviation",
    value: "aviation",
  },
];

const JobLocations = [
  ...defaultAllValue,
  { name: "Cairo, Egypt", value: "cairo" },
  { name: "Lagos, Nigeria", value: "lagos" },
];
const JobWorkplaces = [
  ...defaultAllValue,
  { name: "Remote", value: "remote" },
  { name: "Hybrid", value: "hybrid" },
  { name: "On-Site", value: "on-site" },
];

const AvailableJobs = [
  {
    title: "Treasury & Accounting Manager",
    function: "finance",
    level: "Professional",
    location: "lagos",
    workplace: "on-site",
    date: Date.now(),
  },
  {
    title: "Product Designer",
    function: "tech",
    level: "Professional",
    location: "lagos",
    workplace: "on-site",
    date: Date.now(),
  },
  {
    title: "Graphics Designer",
    function: "it-operation",
    level: "Professional",
    location: "lagos",
    workplace: "on-site",
    date: Date.now(),
  },
  {
    title: "Air Hostess",
    function: "aviation",
    level: "Professional",
    location: "lagos",
    workplace: "on-site",
    date: Date.now(),
  },
  {
    title: "Junior Pilot",
    function: "aviation",
    level: "Professional",
    location: "lagos",
    workplace: "on-site",
    date: Date.now(),
  },
  {
    title: "Treasury & Accounting Manager",
    function: "finance",
    level: "Professional",
    location: "cairo",
    workplace: "on-site",
    date: Date.now(),
  },
];
export { JobFunctions, JobLocations, JobWorkplaces, AvailableJobs };
