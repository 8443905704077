import { Link } from "react-router-dom";

import WhiteAirplane from "../Assets/IMG/WhiteAirplane.svg";
import AgentIcon from "../Assets/IMG/Agent.svg";
import ManageBookingIcon from "../Assets/IMG/ManageBooking.svg";
import AltArrows from "../Assets/IMG/AltArrows.svg";

const MegaSearchMenu = () => {
  return (
    <div className="mega-search-container flex-column">
      <div className="mega-search-nav flex-row">
        <span className="mega-search-nav-button text-white flex-row px-13">
          <img className="mega-search-nav-icon" src={WhiteAirplane} alt="" />{" "}
          &nbsp; &nbsp; Flight
        </span>
        <Link to="/" className="mega-search-nav-link px-13 flex-row">
          <img
            className="mega-search-nav-icon"
            src={ManageBookingIcon}
            alt=""
          />{" "}
          &nbsp; &nbsp; Manage Booking / Check-in
        </Link>
        <Link to="/" className="mega-search-nav-link px-13 flex-row">
          <img className="mega-search-nav-icon" src={AgentIcon} alt="" /> &nbsp;
          &nbsp; Agent
        </Link>
      </div>
      <div className="mega-search-content-container flex-column">
        <div className="mega-search-content flex-row">
          <div className="mega-search flex-row">
            <div className="mega-search-item mega-search-item-small flex-row px-13">
              From
            </div>
            <span className="mega-search-arrows flex-row bg-white">
              <img src={AltArrows} alt="" />
            </span>
            <div className="mega-search-item mega-search-item-small flex-row px-13">
              To
            </div>
            <div className="mega-search-item mega-search-item-large flex-row px-13">
              <div className="mega-search-item-side flex-column">
                <span>Depart</span>
                <span className="text-black">19/11/2022</span>
              </div>
              <span className="text-black">-</span>
              <div className="mega-search-item-side flex-column">
                <span>Return</span>
                <span className="text-black">19/11/2022</span>
              </div>
            </div>
            <div className="mega-search-item mega-search-item-large flex-row px-13">
              <div className="mega-search-item-side flex-column">
                <span>Passenger</span>
                <span className="text-black">Adult</span>
              </div>
              <div className="mega-search-item-side flex-column">
                <span>&nbsp;</span>
                <span className="text-black">
                  <i className="far fa-chevron-down"></i>
                </span>
              </div>
            </div>
            <div className="mega-search-item mega-search-item-large flex-row px-13">
              <div className="mega-search-item-side flex-column">
                <span>Travel Class</span>
                <span className="text-black">Economy</span>
              </div>
              <div className="mega-search-item-side flex-column">
                <span>&nbsp;</span>
                <span className="text-black">
                  <i className="far fa-chevron-down"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="mega-search-footer-container flex-row">
          <div className="mega-search-footer flex-row">
            <Link className="text-gray-default px-13">
              Explore all our flight destinations &nbsp;{" "}
              <i className="far fa-chevron-right"></i>
            </Link>
            <Link className="bg-blue-default btn-default text-white px-13 btn-blue-default flex-row">
              Show Flights &nbsp;{" "}
              <img
                className="mega-search-nav-icon"
                src={WhiteAirplane}
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaSearchMenu;
