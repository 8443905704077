import { Container } from "@mui/material";

import CustomersPurpleIcon from "../Assets/IMG/CustomersPurpleIcon.svg";
import HappyCustomersIcon from "../Assets/IMG/HappyCustomersIcon.svg";

export default function YourGreatDestination() {
  return (
    <div className="your-great-destination flex-column">
      <Container maxWidth="xl">
        <div className="your-great-destination-content flex-column">
          <span className="px-15 text-white">
            About <span className="text-green-default">Air Sierra Leone</span>
          </span>
          <span className="px-27 text-white weight-700">
            Your Great Destination
          </span>
          <span className="px-15 text-white max-800">
            Rano Air is one of the fastest growing Nigerian Airline, founded in
            2019. Rano Air provides affordable and comfortable flights to
            different destinations within and outside Nigeria.
          </span>
          <div className="your-great-destination-badges flex-row">
            <span className="your-great-destination-badge bg-green-default flex-row">
              <span className="your-great-destination-col flex-column">
                <span className="px-20 weight-800 text-green-secondary">
                  2000+
                </span>
                <span className="text-black px-13">Happy Customers</span>
              </span>
              <img src={CustomersPurpleIcon} alt="" />
            </span>
            <span className="your-great-destination-badge bg-white flex-row">
              <span className="your-great-destination-col flex-column">
                <span className="px-20 weight-800 text-gray-default">100%</span>
                <span className="text-gray-secondary px-13">
                  Client Satisfied
                </span>
              </span>
              <img src={HappyCustomersIcon} alt="" />
            </span>
          </div>

          <span className="px-15 text-white">
            Call us to book for your flight{" "}
            <span className="text-green-default">+232 902 9301 1002</span>
          </span>
        </div>
      </Container>
    </div>
  );
}
