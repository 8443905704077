import { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";

import Select from "./Select";

import {
  JobFunctions,
  JobLocations,
  JobWorkplaces,
  AvailableJobs,
} from "../Assets/JSON/RecruitmentData";

import RecruitmentJumboImage from "../Assets/IMG/RecruitmentJumboImage.svg";
import RecruitmentShatteredImage from "../Assets/IMG/RecruitmentShatteredImage.svg";
import BlueLocationIcon from "../Assets/IMG/BlueLocationIcon.svg";
import { getFullDate } from "..";

const Recruitment = () => {
  const [renderedJobs, setRenderedJobs] = useState(AvailableJobs.slice(0, 4));
  const [jobFilterSearchText, setJobFilterSearchText] = useState("");
  const defaultAllValue = {
    name: "All",
    value: "all",
  };

  const [jobFunctionValue, setJobFunctionValue] = useState(defaultAllValue);
  const getJobFunctionValue = (value) => {
    setJobFunctionValue(value);
  };

  const [jobLocationValue, setJobLocationValue] = useState(defaultAllValue);
  const getJobLocationValue = (value) => {
    setJobLocationValue(value);
  };

  const [jobWorkplaceValue, setJobWorkplaceValue] = useState(defaultAllValue);
  const getJobWorkplaceValue = (value) => {
    setJobWorkplaceValue(value);
  };

  const getFullJobLocation = (location) => {
    const loc = JobLocations.filter((jLoc) => jLoc.value === location);
    if (loc.length > 0) {
      return loc[0].name;
    }
  };

  useEffect(() => {
    setRenderedJobs([]);
    let tempJobs =
      jobFunctionValue.value !== "all"
        ? AvailableJobs.filter((job) => job.function === jobFunctionValue.value)
        : AvailableJobs;
    tempJobs =
      jobLocationValue.value !== "all"
        ? tempJobs.filter((job) => job.location === jobLocationValue.value)
        : tempJobs;
    tempJobs =
      jobWorkplaceValue.value !== "all"
        ? tempJobs.filter((job) => job.workplace === jobWorkplaceValue.value)
        : tempJobs;

    if (jobFilterSearchText !== "") {
      tempJobs.map((job) => {
        let { title, level, location, workplace, function: jobFunction } = job;
        location = getFullJobLocation(location);
        let JobFields = [title, level, location, workplace, jobFunction];
        let fields = JobFields.filter(
          (field) =>
            field.toLowerCase().indexOf(jobFilterSearchText.toLowerCase()) !==
            -1
        );
        if (fields.length > 0) {
          setRenderedJobs((prevJobs) => [...prevJobs, job]);
        }
        return true;
      });
    } else {
      setRenderedJobs(tempJobs);
    }
  }, [
    jobFunctionValue,
    jobLocationValue,
    jobWorkplaceValue,
    jobFilterSearchText,
  ]);
  return (
    <div className="recruitment-container">
      <div className="recruitment-jumbo">
        <Container maxWidth="xl" style={{ height: "100%" }}>
          <div className="recruitment-jumbo-row flex-row">
            <div className="recruitment-jumbo-content flex-column">
              <span className="text-white px-27 weight-600">
                Be part of our mission
              </span>
              <span className="text-white px-14">
                We're looking for people to join our amazing team
              </span>
              <a
                href="#offers"
                className="btn-default bg-white flex-row px-15 text-gray-another"
              >
                See Job Offers
              </a>
            </div>
            <img
              src={RecruitmentJumboImage}
              alt=""
              className="recruitment-jumbo-image"
            />
          </div>
        </Container>
      </div>
      <Container maxWidth="xl">
        <div className="more-than-a-place flex-row">
          <img src={RecruitmentShatteredImage} alt="" className="" />
          <div className="flex-column">
            <span className="weight-600 px-21 text-blue-secondary">
              More than a place to work
            </span>
            <br />
            <span
              className="px-16"
              style={{
                lineHeight: "24px",
              }}
            >
              Our work at Air Sierra Limited is to drive change every day, while
              rewarding our top performers
              <br />
              <br />
              We are a platform where ideas thrive and we want every individual
              to find their sense of purpose, belonging and ownership.
            </span>
          </div>
        </div>
      </Container>

      <div className="job-filter-container" id="offers">
        <Container maxWidth="xl">
          <div className="job-filter-row-parent flex-row">
            <div className="job-filter-row flex-row">
              <div className="job-filter-item flex-column">
                <span className="px-15">Job Function</span>
                <Select
                  options={JobFunctions}
                  getSelectValue={getJobFunctionValue}
                  defaultValue={jobFunctionValue}
                />
              </div>
              <div className="job-filter-item flex-column">
                <span className="px-15">Location</span>
                <Select
                  options={JobLocations}
                  getSelectValue={getJobLocationValue}
                  defaultValue={jobLocationValue}
                />
              </div>
            </div>
            <div className="job-filter-row flex-row">
              <div className="job-filter-item flex-column">
                <span className="px-15">Workplace Type</span>
                <Select
                  options={JobWorkplaces}
                  getSelectValue={getJobWorkplaceValue}
                  defaultValue={jobWorkplaceValue}
                />
              </div>
              <div className="job-filter-search-container flex-row">
                <span className="job-filter-search-icon">
                  <i className="far fa-search"></i>
                </span>
                <input
                  type="text"
                  value={jobFilterSearchText}
                  onChange={(e) => {
                    setJobFilterSearchText(e.target.value);
                  }}
                  placeholder="Search"
                  className="job-filter-search"
                  spellCheck={false}
                />
              </div>
            </div>
          </div>
          <div className="recruitment-jobs flex-column">
            {renderedJobs.map((job, index) => {
              return (
                <Link to={`/job/${index}`} className="recruitment-job flex-row">
                  <div className="recruitment-job-left flex-column">
                    <span className="px-21 weight-600 text-blue-secondary">
                      {job.title}
                    </span>
                    <span className="bg-green-default recruitment-job-function flex-row">
                      {job.function}
                    </span>
                    <span className="px-15 text-gray-tertiary">
                      {job.level}
                    </span>
                  </div>
                  <div className="recruitment-job-right flex-column">
                    <span className="px-17 recruitment-job-location">
                      <img src={BlueLocationIcon} alt="" />
                      {getFullJobLocation(job.location)}
                    </span>
                    <span className="bg-white-tertiary px-14 recruitment-job-workplace flex-row">
                      {job.workplace}
                    </span>
                    <span className="text-green-secondary px-15">
                      Posted on {getFullDate(job.date)}
                    </span>
                  </div>
                </Link>
              );
            })}
            {renderedJobs.length === 0 && (
              <div className="no-jobs-row flex-row">
                <span className="no-jobs-icon">
                  <i className="far fa-times-circle"></i>
                </span>
                <span className="px-21">There are no jobs available!</span>
              </div>
            )}
          </div>
          <br />
          <center>
            <button className="load-more-btn load-more-offers px-14 text-gray-secondary flex-row bg-white">
              Load More Offers &nbsp; &nbsp;
              <span className="text-gray-tertiary px-16">
                <i className="far fa-chevron-down"></i>
              </span>
            </button>
          </center>
        </Container>
      </div>
    </div>
  );
};

export default Recruitment;
