import { useState, useEffect } from "react";

export default function Select({
  options,
  placeholder,
  defaultValue,
  getSelectValue,
}) {
  const [currentValue, setCurrentValue] = useState(
    defaultValue || { name: placeholder, value: "" }
  );
  const [isSelectShowing, setSelectShowing] = useState(false);

  useEffect(() => {
    getSelectValue(currentValue);
  }, [currentValue]);

  const toggleSelect = () => {
    setSelectShowing(!isSelectShowing);
  };
  return (
    <>
      <div className="select-container">
        <div
          onClick={toggleSelect}
          className={`select-button flex-row ${
            isSelectShowing ? "select-button-active" : ""
          }`}
        >
          <span className="select-current-value">{currentValue.name}</span>
          <span className="select-icon">
            <i className="far fa-chevron-down"></i>
          </span>
        </div>
        {isSelectShowing && (
          <div
            className={`select-dropdown flex-column ${
              isSelectShowing ? "select-dropdown-active" : ""
            }`}
          >
            {options.map((option) => {
              return (
                <div
                  className="select-dropdown-option flex-row"
                  key={option.value}
                  onClick={() => {
                    setCurrentValue(option);
                    toggleSelect();
                  }}
                >
                  {option.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
